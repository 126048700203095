
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { mapGetters } from 'vuex';

const ENTITY = 'App\\Entity\\ProcessingActivity\\ProcessingActivity';

Vue.use(VueI18n);
const messages = {
  en: require('@/locales/vvt/ProcessEditor/en.json'),
  de: require('@/locales/vvt/ProcessEditor/de.json'),
};

const i18n = new VueI18n({
    fallbackLocale: 'de',
    messages,
    silentTranslationWarn: true
});

export default {
    computed: {
    ...mapGetters({
        getCompanyGroupSettings: 'companyGroupSettings/get'
    }),
    processingActivityFields () {
        return [
            {
                entity: ENTITY,
                fieldName: 'internalId',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.internalId.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'textField',
                defaultValue: null,
                value: null
            },
            {
                entity: ENTITY,
                fieldName: 'status',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.status.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'select',
                multiple: false,
                defaultValue: null,
                value: null,
                itemValue: 'status',
                itemText: 'title',
                items:[
                {
                    status: 'draft', title: i18n.t('states.draft')
                },
                {
                    status: 'review', title: i18n.t('states.review')
                },
                {
                    status: 'approval', title: i18n.t('states.approval')
                },
                {
                    status: 'release', title: i18n.t('states.release')
                },
                {
                    status: 'finalised', title: i18n.t('states.finalised')
                },
                {
                    status: 'archived', title: i18n.t('states.archived')
                }
            ]
            },
            {
                entity: ENTITY,
                fieldName: 'authorityReportRelevance',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.authorityReportRelevance.hint'),
                enabledForGroupHelp: this.getCompanyGroupSettings('useAuthorityReportRelevance'),
                enabledForBulkEdit: this.getCompanyGroupSettings('useAuthorityReportRelevance'),
                fieldType: 'switch',
                defaultValue: false,
                value: false
            },
            {
                entity: ENTITY,
                fieldName: 'controller',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.controller.label'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'switch',
                defaultValue: false,
                value: false
            },
            {
                entity: ENTITY,
                fieldName: 'processor',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.processor.label'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'switch',
                defaultValue: false,
                value: false
            },
            {
                entity: ENTITY,
                fieldName: 'processorCompanies',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.processorCompanies.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'autocomplete',
                multiple: true,
                defaultValue: [],
                value: [],
                itemValue: 'id',
                itemText: 'title',
                fetchItems: 'companies/fetchAll',
                getItems: 'companies/getAllCompanies'
            },
            {
                entity: ENTITY,
                fieldName: 'processorVendorCompanies',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.processorVendorCompanies.shortcut'),
                enabledForGroupHelp: this.getCompanyGroupSettings('useVendor'),
                enabledForBulkEdit: this.getCompanyGroupSettings('useVendor'),
                fieldType: 'autocomplete',
                multiple: true,
                defaultValue: [],
                value: [],
                itemValue: 'id',
                itemText: 'name',
                fetchItems: 'vendor/fetch',
                getItems: 'vendor/getItems'
            },
            {
                entity: ENTITY,
                fieldName: 'processorOutsideCompanies',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.processorOutsideCompanies.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'textArea',
                defaultValue: null,
                value: null
            },
            {
                entity: ENTITY,
                fieldName: 'priority',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.priority.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'select',
                multiple: false,
                defaultValue: null,
                value: null,
                itemValue: 'priority',
                itemText: 'title',
                items:[
                    {
                        priority: 'HIGH',
                        title: i18n.t('vvtProcessEditor.basics.priority.priorities.HIGH')
                    },
                    {
                        priority: 'MEDIUM',
                        title: i18n.t('vvtProcessEditor.basics.priority.priorities.MEDIUM')
                    },
                    {
                        priority: 'LOW',
                        title: i18n.t('vvtProcessEditor.basics.priority.priorities.LOW')
                    }
                ]
            },
            {
                entity: ENTITY,
                fieldName: 'language',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.language.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: false,
                fieldType: 'select',
                multiple: false,
                defaultValue: null,
                value: null,
                itemValue: 'id',
                itemText: 'title',
                fetchItems: 'companyLanguage/fetch',
                getItems: 'companyLanguage/getItems'
            },
            {
                entity: ENTITY,
                fieldName: 'name',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.name.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'textField',
                defaultValue: null,
                value: null
            },
            {
                entity: ENTITY,
                fieldName: 'description',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.description.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'textArea',
                defaultValue: null,
                value: null
            },
            {
                entity: ENTITY,
                fieldName: 'processDocumentation',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.processDocumentation.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'textArea',
                defaultValue: null,
                value: null
            },
            {
                entity: ENTITY,
                fieldName: 'countriesOfAffectedPersons',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.countriesOfAffectedPersons.label'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'autocomplete',
                multiple: true,
                defaultValue: [],
                value: [],
                itemValue: 'countryCode',
                itemText: 'countryName',
                fetchItems: 'countries/fetch',
                getItems: 'countries/getItems'
            },
            {
                entity: ENTITY,
                fieldName: 'department',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.department.shortcut'),
                enabledForGroupHelp: this.getCompanyGroupSettings('useDepartmentInProcessingActivity'),
                enabledForBulkEdit: this.getCompanyGroupSettings('useDepartmentInProcessingActivity'),
                bulkEditRestriction: ['onlyRecordsForSameCompany'],
                fieldType: 'select',
                multiple: false,
                defaultValue: null,
                value: null,
                itemValue: 'id',
                itemText: 'title',
                fetchItems: 'companyDepartments/fetchByCompanyId',
                getItems: 'companyDepartments/getItemsByCompany'
            },
            {
                entity: ENTITY,
                fieldName: 'domain',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.domain.name'),
                enabledForGroupHelp: this.getCompanyGroupSettings('useDomainInProcessingActivity'),
                enabledForBulkEdit: this.getCompanyGroupSettings('useDomainInProcessingActivity'),
                fieldType: 'select',
                multiple: false,
                defaultValue: null,
                value: null,
                itemValue: 'id',
                itemText: 'title',
                fetchItems: 'domain/fetch',
                getItems: 'domain/getItems'
            },
            {
                entity: ENTITY,
                fieldName: 'contactItemsOwner',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.contactItemsOwner.title'),
                enabledForGroupHelp: false,
                enabledForBulkEdit: true,
                fieldType: 'assignment',
                category: 1,
                defaultValue: [],
                value: []
            },
            {
                entity: ENTITY,
                fieldName: 'contactItemsEditor',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.contactItemsEditor.title'),
                enabledForGroupHelp: false,
                enabledForBulkEdit: true,
                fieldType: 'assignment',
                category: 2,
                defaultValue: [],
                value: []
            },
            {
                entity: ENTITY,
                fieldName: 'contactItemsCoordinator',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.contactItemsCoordinator.title'),
                enabledForGroupHelp: false,
                enabledForBulkEdit: true,
                fieldType: 'assignment',
                category: 3,
                defaultValue: [],
                value: []
            },
            {
                entity: ENTITY,
                fieldName: 'contactItemsConsultant',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.contactItemsConsultant.title'),
                enabledForGroupHelp: false,
                enabledForBulkEdit: true,
                fieldType: 'assignment',
                category: 4,
                defaultValue: [],
                value: []
            },
            {
                entity: ENTITY,
                fieldName: 'internalKeywords',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.internalKeywords.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'combobox',
                multiple: true,
                defaultValue: [],
                value: [],
                itemValue: 'id',
                itemText: 'title',
                fetchItems: 'companyInternalKeywords/fetch',
                getItems: 'companyInternalKeywords/getIdItems'
            },
            {
                entity: ENTITY,
                fieldName: 'tools',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.tools.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'combobox',
                multiple: true,
                defaultValue: [],
                value: [],
                itemValue: 'id',
                itemText: 'title',
                fetchItems: 'tools/fetch',
                getItems: 'tools/getIdItems'
            },
            {
                entity: ENTITY,
                fieldName: 'resubmission',
                childFieldName: null,
                name: i18n.t('vvtProcessEditor.basics.resubmission.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: true,
                fieldType: 'date',
                defaultValue: null,
                value: null
            },
            {
                entity: ENTITY,
                fieldName: 'groupOfPerson',
                childFieldName: 'personalDataCategories',
                name: i18n.t('vvtProcessEditor.groupOfPerson.personalDataCategories.shortcut'),
                enabledForGroupHelp: true,
                enabledForBulkEdit: false,
                fieldType: 'combobox',
                multiple: true,
                defaultValue: [],
                value: [],
                itemValue: 'id',
                itemText: 'title',
                fetchItems: 'groupsOfPersons/fetch',
                getItems: 'groupsOfPersons/getIdItems'
            }
        ]
    },
  },
  methods: {
    setLocale(locale) { 
        i18n.locale = locale;
    }
  }
};
